<template>
  <div class="updates_container">
    <v-card shaped class="list_item_container"
         v-for="(update, index) in updates"
         :key="index"
            elevation="4"
    >
      <div class="date_of_update">
        {{ update.date }}
      </div>
      <div class="title_of_update">
        {{ update.title }}
      </div>
      <div class="content_of_update">
        {{ update.content }}
      </div>
    </v-card>
  </div>
</template>

<script>
import updatesData from "@/components/MainApp/LatestUpdates/updatesData";

export default {
  name: "UpdatesList",
  data: () => ({
    updates: updatesData
  })
}
</script>

<style scoped lang="scss">
@import "src/css/main";

.updates_container {
  padding: 1em 2em 1em;
  margin: auto;

  @include md {
    width: 80%
  }

  @include lg {
    width: 70%;
  }

  .list_item_container {
    padding: 2em;

    .date_of_update {
      font-family: $font_content;
      font-size: small;
    }

    .title_of_update {
      font-family: $font_heading;
      font-weight: bolder;
      font-size: large;
    }

    .content_of_update {
      font-family: $font_content;
      font-size: medium;
    }
  }
}
</style>