<template>
<div id="updates_container">
  <Heading heading="Latest Updates" />
  <UpdatesList />
</div>
</template>

<script>
import Heading from "@/components/atom/Heading";
import UpdatesList from "@/components/MainApp/LatestUpdates/UpdatesList";
export default {
  name: "LatestUpdates",
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'We present you our new upcoming service - Pucoreads. All book lovers and readers have a good news as Pucoreads is there to let you sell, exchange and buy books near you. Join Our community and stay tuned for latest updates.'
      },
      {
        name: 'author',
        content: 'Pucosa'
      },
      {
        name: 'keywords',
        content: 'pucoreads, pucosa, books, reading, sell book, used books, upcoming service, upcoming, sell, buy, exchange, buy book, exchange book, book, book lover, websites for books, book app, book apps, latest updates, updates'
      }
    ]
  },
  components: {UpdatesList, Heading}
}
</script>

<style scoped lang="scss">
#updates_container {
  margin-bottom: 4em;
}
</style>